import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

const axiosApi = axios.create({
  // baseURL: 'http://127.0.0.1:8000',
  baseURL: 'https://merch-api.na4u.ru',
  validateStatus: () => true,
});

axiosApi.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  if (store.getters.getAccess) config.headers.common.Authorization = `Bearer ${store.getters.getAccess}`;
  return config;
});

axiosApi.interceptors.response.use(
  async (res) => {
    const originalRequest = res.config;

    if (res.status === 401) {
      if (!originalRequest.retry && store.getters.getRefresh && !originalRequest.url.includes('token')) {
        originalRequest.retry = true;
        await store.dispatch('refresh');
        originalRequest.headers.Authorization = `Bearer ${store.getters.getAccess}`;
        return axiosApi(originalRequest);
      }
    }

    if (res.status > 500) return Promise.reject(res);

    return Promise.resolve(res);
  },
  null,
);

export default axiosApi;

// async (error) => {
//   const originalRequest = error.config;
//
//   console.log(error.response.status);
//
//   if (error.response.status === 401) {
//     if (!originalRequest.retry && store.getters.getRefresh &&
//     !originalRequest.url.includes('token')) {
//       originalRequest.retry = true;
//       await store.dispatch('refresh');
//       originalRequest.headers.Authorization = `Bearer ${store.getters.getAccess}`;
//       return axiosApi(originalRequest);
//     }
//   }
//
//   if (originalRequest.retry && error.response.status < 500) return true;
//
//   return Promise.reject(error);
// },
