import { createStore, createLogger } from 'vuex';
// eslint-disable-next-line import/no-cycle
import cart from './cart';
import modals from './modals';
// eslint-disable-next-line import/no-cycle
import account from './account';
// eslint-disable-next-line import/no-cycle
import products from './products';
// eslint-disable-next-line import/no-cycle
import axiosApi from '../axios/axiosApi';

const plugins = [];
if (process.env.NODE_ENV === 'development') plugins.push(createLogger());

export default createStore({
  plugins,
  state() {
    return {
      access: localStorage.getItem('access'),
      refresh: localStorage.getItem('refresh'),
      user: null,
    };
  },
  getters: {
    getAccess(state) {
      return state.access;
    },
    getRefresh(state) {
      return state.refresh;
    },
    hasTokens(state) {
      return !!(state.access && state.refresh);
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setAccess(state, value) {
      localStorage.setItem('access', value);
      state.access = value;
    },
    setRefresh(state, value) {
      localStorage.setItem('refresh', value);
      state.refresh = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    clearTokens(state) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      state.refresh = '';
      state.access = '';
      state.user = null;
    },
  },
  actions: {
    async login(ctx, payload) {
      const { data } = await axiosApi.post('/api/token/', { email: payload.login, password: payload.password });

      if (data.access) ctx.commit('setAccess', data.access);
      if (data.refresh) ctx.commit('setRefresh', data.refresh);

      return data;
    },
    async refresh(ctx) {
      const { data } = await axiosApi.post('/api/token/refresh/', { refresh: ctx.getters.getRefresh });

      ctx.commit('setAccess', data.access);
      ctx.commit('setRefresh', data.refresh);
    },
    async getUser(ctx) {
      const me = await axiosApi.get('/api/users/me/');

      ctx.commit('setUser', me.data);
    },
    async registration(ctx, props) {
      const { data } = await axiosApi.post('/api/users/create/', { ...props });

      if (data.access) ctx.commit('setAccess', data.access);
      if (data.refresh) ctx.commit('setRefresh', data.refresh);

      return data;
    },
    async passChange(ctx, { password }) {
      const res = await axiosApi.post('/api/users/me/set-password/', { password });

      return res.status === 200;
    },
  },
  modules: {
    products,
    modals,
    cart,
    account,
  },
});
