// eslint-disable-next-line import/no-cycle
import axiosApi from '../axios/axiosApi';

export default {
  namespaced: true,
  state() {
    return {
      products: JSON.parse(localStorage.getItem('productsInCart')) || [],
    };
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
  },
  mutations: {
    addProduct(state, { id, mainProductId }) {
      const productsInCart = state.products;

      const idx = productsInCart.findIndex((el) => el?.id === id);

      if (idx !== -1) productsInCart[idx].count += 1;
      else productsInCart.push({ mainProductId, id, count: 1 });

      localStorage.setItem('productsInCart', JSON.stringify(productsInCart));
    },
    removeProduct(state, { id }) {
      let productsInCart = state.products;

      const idx = productsInCart.findIndex((el) => el?.id === id);

      if (idx === -1) return;

      if (productsInCart[idx].count === 1) {
        productsInCart = productsInCart.filter((el) => el.id !== id);
      } else {
        productsInCart[idx].count -= 1;
      }

      state.products = productsInCart;
      localStorage.setItem('productsInCart', JSON.stringify(productsInCart));
    },
    cartClear(state) {
      state.products = [];
      localStorage.setItem('productsInCart', JSON.stringify([]));
    },
  },
  actions: {
    async createOrder(ctx, payload) {
      const { data } = await axiosApi.post('/api/order/create/', payload);

      if (data.id) ctx.commit('cartClear');

      return data;
    },
  },
};
