// eslint-disable-next-line import/no-cycle
import axiosApi from '../axios/axiosApi';

export default {
  namespaced: true,
  state() {
    return {
      transactions: [],
      orders: [],
    };
  },
  getters: {
    getTransactions(state) {
      return state.transactions;
    },
    getOrders(state) {
      return state.orders;
    },
  },
  mutations: {
    setTransactions(state, newVal) {
      state.transactions = newVal;
    },
    setOrders(state, newVal) {
      state.transactions = newVal;
    },
  },
  actions: {
    async getTransactions(ctx) {
      const { data } = await axiosApi.get('/api/order/transactions/');

      ctx.commit('setTransactions', data);

      return data;
    },
    async getOrders(ctx) {
      const { data } = await axiosApi.get('/api/order/all/');

      ctx.commit('setOrders', data);

      return data;
    },
  },
};
