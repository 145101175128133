export default {
  namespaced: true,
  state() {
    return {
      product: false,
      cart: false,
      account: false,
      enterFormVisible: null,
    };
  },
  getters: {
    getProduct(state) {
      return state.product;
    },
    getCart(state) {
      return state.cart;
    },
    getAccount(state) {
      return state.account;
    },
    getEnterFormVisible(state) {
      return state.enterFormVisible;
    },
  },
  mutations: {
    // product
    showProduct(state, payload) {
      state.product = payload;
    },
    hideProduct(state) {
      state.product = false;
    },
    // cart
    showCart(state) {
      state.cart = true;
    },
    hideCart(state) {
      state.cart = false;
    },
    // account
    showAccount(state) {
      state.account = true;
    },
    hideAccount(state) {
      state.account = false;
    },
    // Enter form (login, registration)
    setEnterFormVisible(state, payload) {
      const newVal = payload === state.enterFormVisible ? null : payload;

      state.enterFormVisible = newVal;
    },
  },
};
