import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth;
  if (requireAuth && store.getters.hasTokens) return next();
  if (requireAuth && !store.getters.hasTokens) return next('/login?message=auth');
  return next();
});

export default router;
