// eslint-disable-next-line import/no-cycle
import axiosApi from '../axios/axiosApi';

export default {
  namespaced: true,
  state() {
    return {
      products: [],
      colors: ['#F5D135', '#F59135', '#35F5E9', '#F5ED35'],
    };
  },
  getters: {
    getProducts(state) {
      const data = [...state.products];
      const { colors } = state;

      const res = data
        .filter((el) => el.products.length)
        .sort((a, b) => b.sort - a.sort)
        .map((el, i) => {
          const idx = i + 1;
          const len = colors.length - 1;
          const colorIdx = idx - (colors.length * Math.ceil(idx / colors.length)) + len;

          el.idx = colorIdx;
          el.color = colors[colorIdx];

          return el;
        });

      return res;
    },
  },
  mutations: {
    setProducts(state, newVal) {
      state.products = newVal;
    },
  },
  actions: {
    async getProducts(ctx) {
      const { data } = await axiosApi.get('/api/product/all/');

      ctx.commit('setProducts', data);

      return data;
    },
  },
};
